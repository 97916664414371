<template v-cloak>
  <div v-loading="loading">
    <div class="course-head">
      <h1>{{ course.course_name }}</h1>
    </div>
    <el-row>
      <el-col :xs="24" :sm="6">
        <div class="playlistMenu">
          <el-collapse accordion v-model="activeMenu">
            <el-collapse-item
              class="play"
              v-for="play in course.playlist"
              :key="play.following_id"
              :name="play.following_id"
            >
              <template slot="title">
                <h3 v-html="getTitle(play.order, play.name)"></h3>
              </template>
              <div v-if="play.children !== '0'">
                <div
                  v-for="(child, child_index) in play.children"
                  :key="child.following_id"
                  :class="{ playChild: true, playChildActive: child.active }"
                >
                  <el-row>
                    <el-col>
                      <div @click="showContent(child)" class="childContent">
                        <span>{{ child_index + 1 }}. {{ child.name }}</span>
                        <div v-if="child.children != 0" class="childFiles">
                          <el-dropdown>
                            <el-button type="primary" size="small" plain>
                              <i class="el-icon-folder-opened"></i> Resources
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                              <el-dropdown-item
                                v-for="item in child.children"
                                :key="item.following_id"
                              >
                                <!-- <a :href="item.url" download
                                  ><i class="el-icon-document"></i
                                  >{{ item.name }}
                                </a> -->
                                <span @click="downloadFile(item.url)"
                                  ><i class="el-icon-document"></i
                                  >{{ item.name }}
                                </span>
                              </el-dropdown-item>
                            </el-dropdown-menu>
                          </el-dropdown>
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </el-col>
      <el-col :xs="24" :sm="18">
        <div v-if="content.active" class="playlist">
          <div class="playlistShow">
            <div v-if="content.type == 'type_video'">
              <iframe
                width="100%"
                height="500"
                :src="getVideo(content.url)"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              >
              </iframe>
            </div>
            <div v-else-if="content.type == 'type_practice'"></div>
            <div v-else-if="content.type == 'type_file'">
              <iframe :src="content.url" width="100%" height="500px"></iframe>
            </div>
          </div>
          <div class="playDetails">
            <el-tabs v-model="activeName">
              <el-tab-pane
                :label="$t('courses.course_description')"
                name="course"
              >
                {{ course.description }}
              </el-tab-pane>
              <el-tab-pane
                :label="$t('courses.chapter_description')"
                name="play"
              >
                {{ content.description }}
              </el-tab-pane>
              <el-tab-pane
                :label="$t('courses.chapter_resources')"
                name="content"
                :disabled="content.children == '0' || content.children == []"
              >
                <div class="playchild-content">
                  <div
                    v-for="item in content.children"
                    :key="item.following_id"
                  >
                    <el-button
                      type="primary"
                      size="mini"
                      @click="downloadFile(item.url)"
                    >
                      <i class="el-icon-document"></i>
                      {{ item.name }}
                    </el-button>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
        <div v-else class="playlist">
          <h1>Welcome!</h1>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import onlineCourseApi from "@/apis/onlineCourse";
import onlineCourseMixin from "@/mixins/onlineCourse.js";

export default {
  name: "course",

  components: {},

  mixins: [onlineCourseMixin],

  props: ["courseId"],
  data() {
    return {
      content: {},
      colors: [
        { color: "#f56c6c", percentage: 20 },
        { color: "#e6a23c", percentage: 60 },
        { color: "#1989fa", percentage: 99 },
        { color: "#5cb87a", percentage: 100 }
      ],
      course: {},
      title: "",
      activeName: "play",
      activeMenu: "",
      percentage: 0,
      loading: false
    };
  },
  computed: {
    ...mapGetters("user", ["langValue"])
  },
  watch: {
    langValue() {
      this.resetData();
    },
    courseId() {
      this.viewCourse();
    }
  },

  mounted() {
    this.viewCourse();
  },
  methods: {
    async viewCourse() {
      this.loading = true;
      const data = await onlineCourseApi.courseEditor({
        course_id: this.courseId
      });
      console.log(data);
      this.setData(data);
      this.content = {};
    },
    setData(data) {
      this.loading = false;
      for (let i in data) {
        if (data[i]["EN-US"]) {
          data[i]["EN-US"].language = "EN-US";
          this.course = data[i]["EN-US"];
        }
      }
    },
    showContent(content) {
      console.log(content);
      console.log(this.course.playlist);
      for (let play_index in this.course.playlist) {
        if (this.course.playlist[play_index]["children"] != "0") {
          for (let playchild_index in this.course.playlist[play_index][
            "children"
          ]) {
            this.course.playlist[play_index]["children"][
              playchild_index
            ].active = false;
          }
        }
      }
      content.active = true;
      this.content = content;
      if (this.content.children == "0" || this.content.children == []) {
        this.activeName = "play";
      } else {
        this.activeName = "content";
      }
    }
  }
};
</script>
<style>
.play > div > .el-collapse-item__header {
  height: auto;
  line-height: normal;
  padding: 10px;
}
.play > div > .el-collapse-item__content {
  padding: 0;
}
</style>
<style scoped>
.course-head {
  padding: 5px 0;
  background: #f8f8f8;
}

.course-head-title {
  text-align: center;
  line-height: 60px;
  vertical-align: middle;
}

.course-head-progress {
  height: 60px;
  text-align: center;
  vertical-align: middle;
}

.playlist {
  border-top: 1px solid #ebeef5;
  border-left: 1px solid #ebeef5;
  min-height: 650px;
  overflow: auto;
}

.playlistShow {
  min-height: 500px;
}

.playDetails {
  padding: 10px;
}

.playChild {
  padding: 5px 0;
  cursor: pointer;
  background: #f8f8f8;
}

.playChild:hover {
  background: #e6f2f5;
  color: #14171c;
}

.playChildActive {
  background: #42a16a;
  color: #fff;
}
.playChildActive:hover {
  background: #42a16a;
  color: #fff;
}

.childTitle {
  padding: 0 10px;
}

.childContent {
  font-size: 14px;
  font-weight: 400;
  padding: 0 10px 0 20px;
}

.childFiles {
  text-align: right;
  padding: 5px;
}

.playchild-content {
  line-height: 26px;
}

.playchild-content a {
  text-decoration: none;
  color: #fff;
}
</style>
