import ApiService from "@/common/api.service";
export default {
  studentCourse(data) {
    return ApiService.post("student_course", data);
  },
  teacherManagement(data) {
    return ApiService.post("teacher_management", data);
  },
  courseEditor(data) {
    return ApiService.query("course_editor", data);
  },
  saveCourseEditor(data) {
    return ApiService.post("save_course_editor", data);
  },
  addCourseList(data) {
    return ApiService.query("add_course_list", data);
  },
  onlineCourses(data) {
    return ApiService.query("online_courses", data);
  },
  courseOutline(data) {
    return ApiService.query("course_outline", data);
  },
  studentManagement(data) {
    return ApiService.query("student_management", data);
  },
  addNewCourse() {
    return ApiService.get("add_new_course", "");
  },
  singleCourse(data) {
    return ApiService.query("single_course", data);
  },
  courseProgress(data) {
    return ApiService.post("course_progress", data);
  },
  onlineCoursesFilter(data) {
    return ApiService.query("online_courses_filter", data);
  },
  verifyOnlineCourse(data) {
    return ApiService.post("verify_online_course", data);
  },
  managerDashboard(data) {
    return ApiService.query("manager_dashboard", data);
  },
  studentsList(data) {
    return ApiService.query("students_list", data);
  },
  assignOnlineCourse(data) {
    return ApiService.post("assign_online_course", data);
  },
  removeStudent(data) {
    return ApiService.post("remove_student", data);
  },
  parentStudentsList(data) {
    return ApiService.query("parent_students_list", data);
  },
  studySchedule(data) {
    return ApiService.query("study_schedule", data);
  }
};
