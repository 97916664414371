<template>
  <div class="container">
    <el-dialog
      :title="$t('courses.view')"
      width="95%"
      top="2.5%"
      :visible.sync="isView"
      center
    >
      <view-course :courseId="courseId"></view-course>
    </el-dialog>
    <div class="courses-title">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ name: 'Home' }">{{
          $t("courses.home")
        }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ $t("courses.courses") }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{
          $t("courses.courses_list")
        }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="courses-list">
      <el-row>
        <el-col :span="12">
          <h3>{{ $t("courses.courses_list") }}</h3>
        </el-col>
      </el-row>
      <el-tabs value="active">
        <el-tab-pane :label="$t('courses.active')" name="active">
          <el-table
            :data="active_courses"
            style="width: 100%"
            :empty-text="$t('courses.empty-text')"
          >
            <el-table-column
              prop="course_name"
              :label="$t('courses.course_name')"
              width="200"
            >
            </el-table-column>
            <el-table-column
              prop="enroll_count"
              :label="$t('courses.students')"
              width="120"
            >
            </el-table-column>
            <el-table-column :label="$t('courses.cover')" width="150">
              <template slot-scope="scope">
                <el-image
                  style="height: 50px;width:70px"
                  :src="scope.row.cover_photo"
                ></el-image>
              </template>
            </el-table-column>
            <el-table-column :label="$t('courses.price')" width="150">
              <template slot-scope="scope">
                {{
                  addCurrencySymbol(scope.row.price, scope.row.price_currency)
                }}
              </template>
            </el-table-column>

            <el-table-column
              prop="created_at"
              :label="$t('courses.date')"
              width="200"
            >
            </el-table-column>
            <el-table-column :label="$t('courses.view')" width="120">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-view"
                  @click="viewCourse(scope.row.course_id)"
                ></el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import onlineCourseApi from "@/apis/onlineCourse";
import ViewCourse from "@/views/courses/components/ViewCourse";

export default {
  name: "BackendCourses",
  components: {
    ViewCourse
  },
  mixins: [],

  props: [],
  data() {
    return {
      searchVal: "",
      courses: [],
      courseId: "",
      isView: false
    };
  },
  computed: {
    ...mapGetters("user", ["langValue"]),
    inactive_courses() {
      let arr = [];
      if (this.courses.length > 0) {
        for (let i in this.courses) {
          if (this.courses[i].effective == 0) {
            arr.push(this.courses[i]);
          }
        }
      }
      return arr;
    },
    active_courses() {
      let arr = [];
      if (this.courses.length > 0) {
        for (let i in this.courses) {
          if (this.courses[i].effective == 1) {
            arr.push(this.courses[i]);
          }
        }
      }
      return arr;
    }
  },
  watch: {
    langValue() {
      this.resetData();
    }
  },

  async mounted() {
    const data = await onlineCourseApi.addCourseList({
      lang: this.langValue
    });
    if (data.status_code !== 0) {
      this.courses = data;
    }
  },
  methods: {
    viewCourse(course_id) {
      this.courseId = course_id;
      this.isView = true;
    },
    add_new_course() {
      this.$confirm(this.$t("courses.tip_message"), this.$t("courses.tip"), {
        confirmButtonText: this.$t("alert.confirm"),
        cancelButtonText: this.$t("alert.cancel"),
        type: "warning"
      })
        .then(async () => {
          const data = await onlineCourseApi.addNewCourse();
          this.$router.push({ name: "teacherEditCourse", query: { id: data } });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("courses.canceled")
          });
        });
    },
    searchCourse() {
      // let value = this.searchVal;
    }
  }
};
</script>

<style scoped>
.backend-body .container .el-dialog__wrapper >>> .el-dialog {
  height: 90%;
  margin: 2.5%;
  overflow: auto;
}

h1,
h2,
h3 {
  margin-bottom: 20px;
}
.courses-title {
  margin: 20px 0;
}

.courses-action {
  margin: 20px 0 30px 0;
  padding: 30px;
  background: #fff;
  border: 1px solid #dcdfe6;
}

.courses-list {
  background: #fff;
  margin: 20px 0;
  padding: 30px;
  border: 1px solid #dcdfe6;
}

.courses-list .course {
  margin: 20px 0;
  border: 1px solid rgba(41, 48, 59, 0.25);
  background-color: #fff;
  height: 120px;
  display: flex;
}

.courses-list .course .courses-manage-link {
  position: absolute;
  display: none;
  height: auto;
  bottom: 0;
  width: auto;
  right: 0;
  top: 0;
  left: 0;
  text-align: center;
  line-height: 118px;
  font-size: 20px;
  background-color: #000;
  opacity: 0.8;
  font-weight: 700;
  color: white;
  text-decoration: none;
}

.courses-list .course:hover .courses-manage-link {
  display: block;
  box-shadow: 0 4px 4px #ccc;
}

.course-img {
  height: 120px;
  width: 100%;
}

.course-img img {
  width: 100%;
  height: 100%;
}

.course-details {
  padding-left: 10px;
}

.details-name {
  line-height: 59px;
  font-weight: 700;
  font-size: 18px;
}

.details-instructor {
  line-height: 59px;
  font-size: 16px;
}
</style>
