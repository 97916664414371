/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
export default {
  methods: {
    getVideo(url) {
      return url.replace("youtu.be/", "www.youtube.com/embed/");
    },
    getTitle(order, name) {
      return "Chapter " + order + ": " + name;
    },
    downloadFile(url) {
      let a = document.createElement("a");
      a.href = url;
      a.download = true;
      a.target = "_blank";
      a.click();
    },
    getFileName(url) {
      let demandName = "";
      if (url !== undefined) {
        let index = url.lastIndexOf("/");
        demandName = url.substring(index + 1, url.length);
      }
      return demandName;
    }
  }
};
